import { Alert, Link } from "@zapier/design-system";
import { styled } from "lib/theme";

const Wrapper = styled.div`
  margin-top: 20px;
`;

export function Banner({
  canCreateProjects,
  isUpgraded,
}: {
  canCreateProjects: boolean;
  isUpgraded: boolean;
}) {
  if (!canCreateProjects || !isUpgraded) {
    const projectCountAlert = !canCreateProjects
      ? "You've reached the limit for Interfaces created on your current plan. "
      : "";
    const message = `Upgrade to unlock ${
      !canCreateProjects ? "more Interfaces, " : ""
    } custom branding & domains, restricted access, and more`;

    return (
      <Wrapper>
        <Alert variant="alert">
          {projectCountAlert}
          {message}. <Link href="/pricing">Learn more.</Link>
        </Alert>
      </Wrapper>
    );
  }

  return null;
}
