import { Button, Icon, Text } from "@zapier/design-system";
import { getZapierBaseUrl } from "lib/route-helpers";
import React, { ReactNode } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  padding: 20px;
  background: var(--zds-colors-neutral-100);
  border: 1px solid var(--zds-colors-neutral-400);
  border-radius: 3px;
  width: 260px;
`;

const Description = styled(Text)`
  display: block;
  margin-top: 10px;
  min-height: 70px;
`;

const Price = styled(Text)`
  display: block;
  margin-top: 15px;
`;

const PriceSubText = styled(Text)`
  display: block;
  margin: 5px 0 24px;
  height: 20px;
`;

const FeatureTitle = styled(Text)`
  display: block;
  margin-top: 24px;
  margin-bottom: 20px;
`;

const Feature = styled.div`
  display: flex;
  align-items: center;
  gap: 1px;
  margin-top: 10px;
`;

export type PricingButtonText =
  | "Sign up today"
  | "Downgrade"
  | "Manage plan"
  | "Upgrade"
  | "Buy now"
  | "Talk to Sales";

const PlanTextWrapper = styled.div`
  margin-top: 24px;
`;

const buttonColors: Record<
  PricingButtonText,
  "primary" | "secondary" | "tertiary"
> = {
  ["Downgrade"]: "secondary",
  ["Manage plan"]: "secondary",
  ["Sign up today"]: "primary",
  ["Upgrade"]: "primary",
  ["Buy now"]: "primary",
  ["Talk to Sales"]: "tertiary",
};

interface PricingCardProps {
  description: string;
  featuresTitle?: string;
  includes?: string[];
  isCurrentPlan?: boolean;
  price: string;
  priceSubText?: ReactNode;
  title: string;
  buttonText: PricingButtonText;
  planText?: string;
  href: string;
  target?: "_blank" | "_self" | "_parent" | "_top";
}

const PricingCard: React.FC<PricingCardProps> = ({
  description,
  featuresTitle,
  includes,
  isCurrentPlan,
  price,
  priceSubText,
  title,
  buttonText,
  planText,
  href,
  target,
}) => {
  const computedButtonText: PricingButtonText = isCurrentPlan
    ? "Manage plan"
    : buttonText;

  return (
    <Wrapper>
      <Text type="SectionHeader" tag="h3">
        {title}{" "}
      </Text>
      <Description type="SmallPrint1" tag="p">
        {description}
      </Description>
      <Price type="PageHeader">{price}</Price>
      {priceSubText ? (
        <PriceSubText type="SmallPrint1">{priceSubText}</PriceSubText>
      ) : null}
      <Button
        color={buttonColors[computedButtonText] ?? "primary"}
        isFullWidth
        href={
          isCurrentPlan ? `${getZapierBaseUrl()}/app/planbuilder/add-ons` : href
        }
        target={target}
      >
        {computedButtonText}
      </Button>
      {planText ? (
        <PlanTextWrapper>
          <Text type="SmallPrint2">{planText}</Text>
        </PlanTextWrapper>
      ) : null}

      {featuresTitle ? (
        <>
          <FeatureTitle type="SmallPrint3">{featuresTitle}</FeatureTitle>
          {includes?.map((item, index) => (
            <Feature key={index}>
              <Icon color="StatusSuccessStronger" size={24} name="formCheck" />
              <Text type="Body2">{item}</Text>
            </Feature>
          ))}
        </>
      ) : null}
    </Wrapper>
  );
};

export default PricingCard;
