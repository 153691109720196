import { Icon, Text } from "@zapier/design-system";

import { styled } from "lib/theme";

const ProjectOverviewMenuItemWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 150px;
`;

interface ProjectOverviewMenuItemProps {
  projectOverviewUrl: string;
}

export function ProjectOverviewMenuItem({
  projectOverviewUrl,
}: ProjectOverviewMenuItemProps) {
  return (
    <ProjectOverviewMenuItemWrapper
      onClick={() => (window.location.href = projectOverviewUrl)}
    >
      <Icon name="actionEdit" size={20} />
      <Text type="Body3" color="inherit" hasTransition={false}>
        Overview
      </Text>
    </ProjectOverviewMenuItemWrapper>
  );
}
