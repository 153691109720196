import { ZslTemplate } from "@/server/services/zslapi";
import { Colors, Link, Spacer, Text } from "@zapier/design-system";
import { css, styled } from "lib/theme";
import { Fragment } from "react";

const PreviewImageWrapper = styled.div`
  height: 190px;
  overflow: hidden;
`;

const PreviewImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: top center;
`;

const DetailWrapper = styled.div`
  padding: 20px;
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 20px;
  border-top: 1px solid ${Colors.GrayWarm4};
  position: relative;
`;

const IconWrapper = styled.div`
  padding-top: 3px;
`;

const Icon = styled.img`
  width: 34px;
  height: 34px;
  object-fit: cover;
  object-position: center;
  border-radius: 6px;
`;

const TextWrapper = styled.div`
  color: ${Colors.GrayWarm10};
`;

const TitleWrapper = styled.div``;

const Wrapper = styled.button`
  all: unset;

  display: grid;
  grid-template-rows: auto 1fr;

  border-radius: 12px;
  overflow: hidden;
  border: 1px solid ${Colors.GrayWarm4};

  ${({ onClick }) =>
    onClick
      ? css`
          cursor: pointer;

          transition: border-color 150ms;
          ${DetailWrapper} {
            transition: background-color 150ms;
          }
          ${TitleWrapper}, ${TitleWrapper} * {
            transition: color 150ms;
          }

          &:hover {
            border-color: ${Colors.UiPrimaryStrongest};

            ${DetailWrapper} {
              background-color: ${Colors.UiPrimaryWeakest};
            }

            ${TitleWrapper} {
              color: ${Colors.UiPrimaryStrongest};
            }
          }
        `
      : css`
          cursor: not-allowed;
        `}
`;

const ExceedsTablesLimit = styled.div`
  position: absolute;
  bottom: calc(100% + 1px);
  width: 100%;

  font-family: ${({ theme }) => theme.app.fontFamily};
  font-size: 12px;
  line-height: 16px;

  padding: 10px;

  background-color: ${Colors.StatusWarning};
  color: ${Colors.GrayWarm10};
  border-top: 1px solid ${Colors.GrayWarm4};

  & > a {
    color: ${Colors.GrayWarm10};
    font-weight: 600;
  }
`;

export type TemplateItemProps = {
  title: string;
  description?: string;
  zsl: ZslTemplate;
  icon?: string;
  id: string;
  previewImage?: string;
  onClick?: () => void;
  onUpgrade?: (templateKey: string) => void;
  isPaidAccount: boolean;
  tablesCount: number;
  exceedsTablesLimit?: boolean;
  zapierUpgradeUrl: string;
};

export function TemplateItem(props: TemplateItemProps) {
  const tables = props.tablesCount === 1 ? "Table" : "Tables";
  return (
    <Wrapper onClick={props.onClick} disabled={!props.onClick}>
      <PreviewImageWrapper>
        {props.previewImage ? (
          <PreviewImage src={props.previewImage} alt="" />
        ) : null}
      </PreviewImageWrapper>
      <DetailWrapper>
        {props.exceedsTablesLimit ? (
          <ExceedsTablesLimit>
            {props.isPaidAccount ? (
              <>
                Requires {props.tablesCount} additional {tables} which exceeds
                your limit.
              </>
            ) : (
              <>
                <Link
                  href={props.zapierUpgradeUrl}
                  target="_blank"
                  color="inherit"
                  onClick={() => props.onUpgrade?.(props.id)}
                >
                  Upgrade your Tables limits
                </Link>{" "}
                to use this template.
              </>
            )}
          </ExceedsTablesLimit>
        ) : null}
        {props.icon ? (
          <IconWrapper>
            <Icon src={props.icon} alt="" />
          </IconWrapper>
        ) : null}
        <TextWrapper>
          <TitleWrapper>
            <Text tag="div" type="Body3" color="currentColor">
              {props.title}
            </Text>
          </TitleWrapper>
          {props.description ? (
            <Fragment>
              <Spacer height={5} />
              <Text tag="div" type="SmallPrint1" color="currentColor">
                {props.description}
              </Text>
            </Fragment>
          ) : null}
        </TextWrapper>
      </DetailWrapper>
    </Wrapper>
  );
}
