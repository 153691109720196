import styled from "styled-components";
import { Icon, Text } from "@zapier/design-system";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

interface UsageProps {
  count: number;
}

const USAGE_THRESHOLD = 100;

export const Usage = ({ count }: UsageProps) => {
  const isNew = count < USAGE_THRESHOLD;

  return (
    <Wrapper>
      <Icon name={isNew ? "miscFire" : "arrowBranch"} size={18} />
      <Text type="SmallPrint2">{isNew ? "New" : count.toLocaleString()}</Text>
    </Wrapper>
  );
};
