import { Menu as ZMenu, MenuItem as ZMenuItem } from "@zapier/design-system";
import { styled } from "lib/theme";
import { MouseEventHandler, ReactNode } from "react";

export type MenuItem = {
  children: ReactNode;
  onClick?: (e?: React.MouseEvent) => void;
  href?: string;
  disabled?: boolean;
};

export const MenuPaddingWrapper = styled.div`
  padding: 5px;
`;

export type MenuProps = {
  ariaLabel: string;
  menuItems: MenuItem[];
  stopClickPropagation?: boolean;
};

export function Menu({
  ariaLabel,
  menuItems,
  stopClickPropagation,
}: MenuProps) {
  const renderedItems = menuItems.map((item, i) => {
    return (
      <ZMenuItem
        key={i}
        onClick={item.onClick}
        href={item.href}
        disabled={item.disabled}
      >
        {item.children}
      </ZMenuItem>
    );
  });

  let handleClick: MouseEventHandler | undefined = undefined;
  if (stopClickPropagation) {
    handleClick = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };
  }

  return (
    <div onClick={handleClick}>
      <ZMenu aria-label={ariaLabel}>{renderedItems}</ZMenu>
    </div>
  );
}
