import {
  Checkbox,
  Colors,
  FormLabel,
  Icon,
  Link,
  Shadows,
  TableRow,
  TableTd,
  Text,
  TooltipWrapper,
} from "@zapier/design-system";
import { ModalDeleteProject } from "components/ModalDeleteProject";
import {
  pagePath,
  projectPath,
  projectUrl as getProjectUrl,
  publishedProjectUrl,
} from "lib/route-helpers";
import { Suspense, useEffect, useRef, useState } from "react";
import { ProjectListItem, trpc } from "utils/trpc";
import { CreatorAvatar } from "./components";
import { ProjectPathLabel } from "./ProjectPathLabel";
import { styled } from "lib/theme";
import { FloatingMenu } from "components/Menus/FloatingMenu";
import { useUser } from "lib/context/user-context";
import { ZSLMenuItem } from "./ZSLMenuItem";
import { MenuItem } from "components/Menus/Menu";
import ZSLModal from "./ZSLModal";
import { formatDistanceToNow } from "date-fns";
import { ProjectAuthType } from "@prisma/client";
import { ProjectOverviewMenuItem } from "./ProjectOverviewMenuItem";
import { CloneMenuItem } from "./CloneMenuItem";
import DuplicateModal, { DuplicateModalState } from "./DuplicateModal";
import { clients, zrpc } from "@/lib/zrpc";

const ProjectMenuWrapper = styled.div`
  margin-left: auto;
  position: relative;
`;

const DeleteMenuItemWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  color: ${Colors.StatusErrorWeaker};
  width: 150px;
`;

const AccessWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  min-width: 200px;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  border: 1px solid ${Colors.GrayWarm6};
  transition: all 300ms ease-in-out;
  padding: 5px 4px;
  border-radius: 5px;
  cursor: pointer;
  color: ${Colors.GrayWarm6};
  &:hover {
    color: ${Colors.GrayWarm10};
    box-shadow: ${Shadows.elevation6};
  }
`;

const ProjectTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  maxwidth: 400px;
  cursor: pointer;
`;

const ProjectTitleTextWrapper = styled(ProjectTitleWrapper)`
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  width: 95%;
`;

const AvatarLink = styled(Link)`
  display: flex;
`;

function getProjectAuthIcon(authType: ProjectAuthType) {
  switch (authType) {
    case ProjectAuthType.None:
      return <Icon name="miscWorld" size={22} color="GrayWarm6" />;
    case ProjectAuthType.Password:
      return <Icon name="actionLock" size={22} color="GrayWarm6" />;
    case ProjectAuthType.Stytch:
    case ProjectAuthType.Consumer:
      return <Icon name="personGroup" size={22} color="GrayWarm6" />;
    default:
      const _exchaustiveCheck: never = authType;
      return _exchaustiveCheck;
  }
}

function getProjectAuthLabel(authType: ProjectAuthType) {
  switch (authType) {
    case ProjectAuthType.None:
      return "Anyone can access";
    case ProjectAuthType.Password:
      return "Password protected";
    case ProjectAuthType.Stytch:
    case ProjectAuthType.Consumer:
      return "Managed users can access";
    default:
      const _exchaustiveCheck: never = authType;
      return _exchaustiveCheck;
  }
}

export function ListItem(
  props: Readonly<{
    projectListItem: ProjectListItem;
    selected: boolean;
    handleSelect: (projectId: string) => void;
    showClone?: boolean;
  }>
) {
  const [confirmDeleteIsOpen, setConfirmDeleteIsOpen] = useState(false);
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const iconRef = useRef<HTMLDivElement>(null);

  const [zsl, setZsl] = useState<string>("");
  const [zslModalIsOpen, setZslModalIsOpen] = useState(false);
  const [duplicateModalState, setDuplicateModalState] =
    useState<DuplicateModalState | null>(null);

  const generateTemplate = zrpc.zslapi.useMutation(
    "post",
    "/api/zsl/v0/templates/generate"
  );
  const executeTemplate = zrpc.zslapi.useMutation(
    "post",
    "/api/zsl/v0/templates/execute"
  );

  const getConnectedAssetIds = async (
    interfaceId: string
  ): Promise<{
    tableIds: string[];
    chatbotIds: string[];
    zapIds: string[];
  }> => {
    const resp = await clients.interfaces.GET(
      "/api/interfaces/v0/interfaces/{interfaceId}/linked-assets",
      {
        params: { path: { interfaceId } },
      }
    );

    if (resp.error) {
      throw new Error("Failed to fetch connected assets");
    }

    return {
      tableIds: resp.data.data
        .filter((la) => la.asset_type === "table")
        .map((la) => la.asset_id),
      chatbotIds: resp.data.data
        .filter((la) => la.asset_type === "chatbot")
        .map((la) => la.asset_id),
      zapIds: resp.data.data
        .filter((la) => la.asset_type === "zap")
        .map((la) => la.asset_id),
    };
  };

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const { user } = useUser();

  const {
    projectListItem: { project, pageCount, thumbnailPage },
  } = props;

  const singlePageMode = pageCount === 1;

  const name = project.name || "Untitled Interface";

  const duplicateTemplate = async () => {
    setDuplicateModalState({ status: "cloning", zsl: null });

    try {
      const connectedAssetIds = await getConnectedAssetIds(project.id);

      const response = await generateTemplate.mutateAsync({
        params: {
          query: {
            fetchInterfacesConnectedAssets: true,
          },
        },
        body: {
          ...connectedAssetIds,
          interfaceIds: [project.id],
        },
      });

      if (!response.data.template) {
        throw new Error("Failed to generate template from interface");
      }

      setDuplicateModalState({
        status: "creating",
        zsl: response.data.template,
      });

      const executeResponse = await executeTemplate.mutateAsync({
        body: {
          input: {},
          template: response.data.template,
        },
      });

      setDuplicateModalState({
        status: "finished",
        zsl: null,
        createdTemplate: executeResponse.data,
      });
    } catch (err: unknown) {
      setDuplicateModalState({ status: "error", zsl: null });
      throw err;
    }
  };

  const handleZsl = async () => {
    setZslModalIsOpen(true);

    const response = await generateTemplate.mutateAsync({
      params: {
        query: {
          fetchInterfacesConnectedAssets: true,
        },
      },
      body: {
        interfaceIds: [project.id],
      },
    });
    if (!response.data.template) {
      throw new Error("Failed to generate template from interface");
    }

    setZsl(JSON.stringify(response.data.template, null, 2));
  };

  const href =
    singlePageMode && thumbnailPage?.id
      ? pagePath({ pageId: thumbnailPage.id })
      : projectPath({ projectId: project.id });

  const { data: customDomainCheck } = trpc.projects.checkCustomDomain.useQuery(
    {
      projectId: project.id,
    },
    { enabled: Boolean(project.customDomain) }
  );

  const projectUrl = publishedProjectUrl({
    projectSlug: project.slug,
    customDomain: project.customDomain,
    customDomainConfigured: customDomainCheck?.configured,
  });

  const copyToClipboard = async (url: string) => {
    await navigator.clipboard.writeText(url);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (iconRef.current && !iconRef.current.contains(event.target as Node)) {
        setIsCopied(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const menuItems: MenuItem[] = [
    {
      children: (
        <DeleteMenuItemWrapper>
          <Icon name="actionTrash" size={22} />
          <Text type="Body3" color="inherit" hasTransition={false}>
            Delete
          </Text>
        </DeleteMenuItemWrapper>
      ),
      onClick: (e?: React.MouseEvent) => {
        e?.preventDefault();
        setConfirmDeleteIsOpen(true);
      },
    },
    {
      children: (
        <ProjectOverviewMenuItem
          projectOverviewUrl={getProjectUrl({ projectId: project.id })}
        />
      ),
      onClick: (e?: React.MouseEvent) => {
        e?.preventDefault();
      },
    },
  ];

  if (user?.isStaff) {
    menuItems.unshift({
      children: <ZSLMenuItem />,
      onClick: () => void handleZsl(),
    });
  }

  if (user?.isStaff || props.showClone) {
    menuItems.unshift({
      children: <CloneMenuItem />,
      onClick: () => void duplicateTemplate(),
    });
  }

  return (
    <>
      <TableRow>
        <TableTd>
          <FormLabel>
            <Checkbox
              aria-label={`Select Interface ${name}`}
              checked={props.selected}
              name="checkbox"
              onChange={() => props.handleSelect(project.id)}
            />
          </FormLabel>
        </TableTd>
        <TableTd>
          <Link href={href} style={{ all: "unset" }}>
            <ProjectTitleWrapper data-testid="project-name">
              <ProjectTitleTextWrapper>
                <Text type="SmallPrint3" color="inherit" hasTransition={false}>
                  {name}
                </Text>
              </ProjectTitleTextWrapper>
            </ProjectTitleWrapper>
          </Link>
        </TableTd>
        <TableTd>
          <Suspense fallback={<div />}>
            <ProjectPathLabel projectUrl={projectUrl} />
          </Suspense>
        </TableTd>
        <TableTd>
          <div ref={iconRef}>
            <TooltipWrapper
              content={isCopied ? "Copied!" : "Copy link"}
              position="north"
            >
              {({ childProps }) => (
                <IconWrapper
                  {...childProps}
                  onClick={() => copyToClipboard(projectUrl)}
                >
                  <Icon
                    name={isCopied ? "actionCopyFill" : "actionCopy"}
                    size={22}
                  />
                </IconWrapper>
              )}
            </TooltipWrapper>
          </div>
        </TableTd>
        <TableTd>
          <Link href={href} style={{ all: "unset" }}>
            <Text type="SmallPrint2" color="GrayWarm10">
              {formatDistanceToNow(
                new Date(project.updatedAt ?? project.createdAt),
                {
                  addSuffix: true,
                }
              )}
            </Text>
          </Link>
        </TableTd>
        <TableTd>
          <Link href={href} style={{ all: "unset" }}>
            <AccessWrapper>
              {getProjectAuthIcon(project.authType)}
              <Text type="SmallPrint2" color="GrayWarm10">
                {getProjectAuthLabel(project.authType)}
              </Text>
            </AccessWrapper>
          </Link>
        </TableTd>
        <TableTd>
          <AvatarLink href={href}>
            <CreatorAvatar creator={project.creator} />
          </AvatarLink>
        </TableTd>
        <TableTd>
          <ProjectMenuWrapper>
            <IconWrapper
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setMenuIsOpen(true);
              }}
            >
              <Icon name="navMoreHoriz" size={22} />
            </IconWrapper>
            {menuIsOpen ? (
              <FloatingMenu
                ariaLabel="Interface menu"
                menuItems={menuItems}
                onClickOutside={() => setMenuIsOpen(false)}
                position="south"
                align="right"
              />
            ) : null}
          </ProjectMenuWrapper>
        </TableTd>
      </TableRow>
      {confirmDeleteIsOpen ? (
        <ModalDeleteProject
          projectId={project.id}
          onClosed={() => setConfirmDeleteIsOpen(false)}
        />
      ) : null}
      {duplicateModalState?.status ? (
        <DuplicateModal
          closeModal={() => setDuplicateModalState(null)}
          setState={setDuplicateModalState}
          state={duplicateModalState}
        />
      ) : null}
      {zslModalIsOpen ? (
        <ZSLModal zsl={zsl} closeModal={() => setZslModalIsOpen(false)} />
      ) : null}
    </>
  );
}
