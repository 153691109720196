import { Icon, Text } from "@zapier/design-system";

import { styled } from "lib/theme";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 150px;
`;

export function CloneMenuItem() {
  return (
    <Wrapper>
      <Icon name="actionCopy" size={20} />
      <Text type="Body3" color="inherit" hasTransition={false}>
        Clone
      </Text>
    </Wrapper>
  );
}
