import styled from "styled-components";

import { Button, Text } from "@zapier/design-system";

const Wrapper = styled.section`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

export const EmptyState = () => {
  return (
    <Wrapper>
      <Text type="ParagraphHeader1" tag="h2">
        No templates found
      </Text>
      <Button
        color="primary"
        size="small"
        target="_blank"
        href="https://template-request.zapier.app"
      >
        Request a template
      </Button>
    </Wrapper>
  );
};
