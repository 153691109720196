import styled from "styled-components";
import { Colors, Icon, Spacer, Text } from "@zapier/design-system";
import Image, { StaticImageData } from "next/image";

const Body = styled.div`
  padding: 10px 20px 15px;
  background: rgba(${Colors.PrimeWhite}, 0.3);
`;

const HoverContent = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  background: ${Colors.BrandNight};
  padding: 10px 20px 15px;
  color: ${Colors.PrimeWhite};
  transition: opacity 150ms;
`;

const ZapTrigger = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
`;

const Wrapper = styled.button`
  appearance: none;
  width: 100%;
  border-radius: 5px;
  background: ${Colors.BrandSky};
  overflow: hidden;
  transition: background 150ms;
  position: relative;
  &:hover,
  &:focus {
    ${HoverContent} {
      opacity: 1;
    }
  }
`;

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Description = styled.div`
  text-align: left;
  flex: 1;
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 128px;
  position: relative;
`;

interface SimpleCardProps {
  image: StaticImageData;
  onClick: () => void;
  title: string;
  triggers: string[];
}

export const SimpleCard = ({
  image,
  onClick,
  title,
  triggers,
}: SimpleCardProps) => {
  return (
    <Wrapper onClick={onClick}>
      <ImageWrapper>
        <Image src={image} alt={title} fill />
      </ImageWrapper>
      <Body>
        <Title>
          <Text type="ParagraphHeader2">{title}</Text>
          <Icon name="formAdd" size={20} />
        </Title>
        <Description>
          <Text type="SmallPrint1">{`${triggers.length} trigger`}</Text>
        </Description>
      </Body>
      <HoverContent>
        <Title>
          <Text color="PrimeWhite" type="ParagraphHeader2">
            {title}
          </Text>
          <Icon name="formAdd" size={20} />
        </Title>
        <Description>
          <Text color="PrimeWhite" type="SmallPrint1">
            Trigger a zap when:
          </Text>
        </Description>
        <Spacer height={10} />
        {triggers.map((trigger) => (
          <ZapTrigger key={trigger}>
            <Icon name="miscStepsAlt" size={18} />
            <Text color="PrimeWhite" type="SmallPrint2">
              {trigger}
            </Text>
          </ZapTrigger>
        ))}
      </HoverContent>
    </Wrapper>
  );
};
