import {
  Button,
  ModalContent,
  ModalContentBody,
  Text,
} from "@zapier/design-system";

import { Modal } from "components/Modal";
import { styled } from "lib/theme";

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const Code = styled.code`
  overflow: scroll;
  padding: 20px;
  border-radius: 8px;
  background: var(--zds-colors-night);
  color: #fff;
  width: 100%;
  height: 500px;
  display: block;
`;

const Loader = styled.div`
  padding: 20px;
  text-align: center;
`;

interface ZSLModalProps {
  closeModal: () => void;
  zsl?: string;
}

const PrettyPrintJson = ({ data }: { data: string }) => (
  <Code>
    <pre>{data}</pre>
  </Code>
);

const ZSLModal = ({ closeModal, zsl }: ZSLModalProps) => {
  const copyText = (e: any) => {
    e.preventDefault();

    navigator.clipboard
      .writeText(zsl ?? "")
      .then(() => {
        closeModal();
      })
      .catch((error) => {
        console.error("Error copying text: ", error);
      });
  };

  return (
    <Modal onClosed={closeModal}>
      <ModalContent maxWidth="1000px">
        <ModalContentBody>
          <Header>
            <Text type="PageHeader">Template in ZSL</Text>
            {zsl ? (
              <Button color="tertiary" onClick={copyText}>
                Copy Template
              </Button>
            ) : null}
          </Header>
          {zsl ? (
            <PrettyPrintJson data={zsl} />
          ) : (
            <Loader>Generating ZSL...</Loader>
          )}
        </ModalContentBody>
      </ModalContent>
    </Modal>
  );
};

export default ZSLModal;
